<template>
	<div dark>
		
		<div style="display: flex">
			<div class="mr-2 pa-2" style="min-width: 150px; max-width: 250px">
				<v-card elevation="4" class="pa-2 mb-4">
					<h3>Weergave:</h3>
					<v-radio-group v-model="viewKeuze" class="ma-0">
						<v-radio label="data-table" value="dtt"></v-radio>
						<v-radio label="cs-table" value="cst"></v-radio>
						<v-radio label="xls-table" value="xlt"></v-radio>
					</v-radio-group>

					<div v-show="viewKeuze == 'xlt'">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mr-2 primary"
									@click="exportAsExcel"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-microsoft-excel</v-icon>
								</v-btn>
							</template>
							<template>excel export</template>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									class="mr-2 primary"
									@click="exportAsCsv"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-arrow-right</v-icon>
								</v-btn>
							</template>
							<span>CSV export</span>
						</v-tooltip>
					</div>
				</v-card>

				<v-card elevation="4" class="pa-2 mb-4 overflow-x-hidden">
					<h3>Filters:</h3>

					<table class="tbl_sm pr-2">
						<tr v-for="(field, index) in fieldHeaders" :key="index">
							<td>{{ field.text }}</td>
							<td>
								<input
									v-model="field.model"
									:placeholder="field.text"
									style="max-width: 118px"
                                    @keyup = "storeModel(field)"
								/>
							</td>
						</tr>
					</table>
				</v-card>

				<v-card elevation="4" class="pa-2 mb-4">
					<!-- aSelectedFields = {{aSelectedFields}}<br>
                     aFields = {{aFields}}<br> -->
					<div style="display: flex" class="mb-3">
						<h3>Velden:</h3>
						<v-spacer />
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									class="ma-1"
									@click="
										setSubjectLocStorage(
											subjectName,
											aSelectedFields
										)
									"
									v-bind="attrs"
									v-on="on"
								>
									mdi-content-save-move-outline
								</v-icon>
							</template>
							<span>Selectie opslaan als standaard</span>
						</v-tooltip>
					</div>

					<v-select
						v-model="aSelectedFields"
						@change="getFieldHeaders"
						:items="aFields"
						label="Selecteer velden"
						multiple
					>
						<template v-slot:selection="{ item, index }">
							<v-chip v-if="index === 0">
								<span>{{ item }}</span>
							</v-chip>
							<span
								v-if="index === 1"
								class="grey--text text-caption"
							>
								(+{{ aSelectedFields.length - 1 }} others)
							</span>
						</template>
					</v-select>

                    <h4>orden velden</h4>
					<draggable
						v-model="aSelectedFields"
						v-bind="dragOptions"
						@start="drag = true"
						@end="drag = false"
                        @change = "updateItems"
					>
						<transition-group type="transition">
							<!-- :name="!drag ? 'flip-list' : null" -->
							<div
								class="list-group-item"
								v-for="field in aSelectedFields" :key="field"
							>
								<span style="font-size:85%">
									<v-icon small class="ml-2">mdi-timeline-remove-outline</v-icon> {{ field }}
								</span>
							</div>
						</transition-group>
					</draggable>
				</v-card>

                <v-card v-if="subjectName == 'studenten'" elevation="4" class="pa-2 mb-4 overflow-x-hidden">
                    <h3>SQL exbeo:</h3>
                    <v-btn @click="createSQL" class="mt-1 mb-3">create SQL <i>checked!!</i></v-btn>

                    <v-text-field
                        v-model="team_cohort"
                        label="team_cohort"
                        placeholder="team_cohort"
                        style="font-size:80%"
                    ></v-text-field>
                    <v-text-field
                        v-model="team_id"
                        label="team_id"
                        placeholder="team_id"
                        style="font-size:80%"
                    ></v-text-field>
                    <v-text-field
                        v-model="opl"
                        label="opl"
                        placeholder="opl"
                        style="font-size:80%"
                    ></v-text-field>

                    <v-textarea
                        outlined
                        name="input-7-4"
                        label="Outlined textarea"
                        :value="sqlExbeoA"
                    ></v-textarea>
                    
                </v-card>
			</div>

            <!-- this.fieldHeaders = {{this.fieldHeaders}} -->

			<div class="mr-2 pa-2" style="overflow: auto; max-height: 85vh">
				<v-card elevation="4" class="pa-4">
					<div v-if="1">
						<div v-show="viewKeuze == 'dtt'">
							<v-data-table
								:headers="fieldHeaders"
								:items="all_ItemsFilterd"
								:items-per-page="15"
								class="elevation-1"
							></v-data-table>
						</div>

						<div v-if="viewKeuze == 'cst'">
							<table class="tbl_sm">
								<tr>
									<td
										:colspan="fieldHeaders.length + 2"
										@click="chkAllItems()"
									>
										<input
											type="checkbox"
											v-model="modelCheckAll"
											class="mt-1"
										/>
										check all
									</td>
								</tr>
								<tr>
									<td :colspan="fieldHeaders.length + 2">
										<b
											>{{
												all_ItemsFilterd.length
											}}
											studenten</b
										>
										gefilterd van totaal:
										<b>{{ items.length }}</b>
									</td>
								</tr>
								<tr>
									<th></th>
									<th></th>
									<th
										v-for="field in fieldHeaders"
										:key="field.value"
									>
										{{ field.text }}
									</th>
								</tr>

								<tr
									v-for="(item, index) in all_ItemsFilterd"
									:key="item.id"
								>
									<td>
										<input
											type="checkbox"
											v-model="item.checked"
											class="mt-1"
										/>
									</td>
									<td>{{ index + 1 }}</td>
									<td
										v-for="field in fieldHeaders"
										:key="field.value"
									>
										{{ item[field.value] }}
									</td>
								</tr>
							</table>
						</div>

						<div v-show="viewKeuze == 'xlt'">
							<vue-excel-editor
								v-model="all_ItemsFilterd"
								ref="grid"
								v-show="true"
							>
								<vue-excel-column
									v-for="f in fieldHeaders"
									:key="f.value"
									:field="f.value"
									:label="f.value"
								/>
							</vue-excel-editor>
						</div>
					</div>
				</v-card>
			</div>
		</div>
        
	</div>
</template>

<script>
import draggable from "vuedraggable";

export default {
	components: {
		draggable,
	},
	props: ["subjectName", "items", "aStandardSelFields", "subjectTitle"],
	//https://stackoverflow.com/questions/50951627/vue-2-load-ajax-data-before-component-render
	data: () => ({
		viewKeuze: "cst",
		all_items: [],
		modelCheckAll: false,
		fieldHeaders: [],
		aSelectedFields: [],
		aFields: [],
        sqlExbeoA: "test",
        team_cohort: "JA19",
        team_id: "1",
        opl: "1",
        histSearch: {},
        

		drag: false,
	}),

	mounted() {
		this.aSelectedFields = this.aStandardSelFields;
		let savedSelFields = JSON.parse(
			localStorage.getItem(this.subjectName)
		);
		if (savedSelFields) {
			this.aSelectedFields = savedSelFields;
			//console.log("in mounted, saved in localStorage fields. this.subjectName:" +this.subjectName	);
			//console.log("ifelds:", savedSelFields);
		}
	},

	methods: {

        storeModel(field) { //functie nodig omdat bij refresh van fieldHeaders anders de oude search models gereset worden naar leeg
            //console.log("field.model=",field.model);
            this.histSearch[field.value] = field.model
            //console.log("this.histSearch=", this.histSearch);
        },
       
		createSQL() {
            console.log("all_ItemsFilterd = ", this.all_ItemsFilterd);
            let sql = "";
            for(let i=0; i<this.all_ItemsFilterd.length; i++) {
                let aStd = this.all_ItemsFilterd[i];
                if(aStd.checked) {
                    //sql += " INSERT INTO table (id, name, age) VALUES(1, "A", 19) ON DUPLICATE KEY UPDATE name="A", age=19";
                    sql += " INSERT INTO lesplan__student (vnaam, tv, anaam, std_nummer, groep, crebo, team_coh, team_id, id_opl) ";
                    sql += " VALUES ( '"+aStd.vnaam+"', '"+aStd.tv+"', '"+aStd.anaam+"', '"+aStd.stdnr+"', '"+aStd.groep+"', '"+aStd.id_crebo+"', '"+this.team_cohort + "', '"+this.team_id + "','"+this.opl + "') ";
                    sql += " ON DUPLICATE KEY UPDATE team_coh='"+this.team_cohort + "' ; ";
                }
            }
            this.sqlExbeoA = sql;
		},

		chkAllItems() {
			this.items.map((obj) => {
				obj.checked = !this.modelCheckAll;
				return obj;
			});
		},

		exportAsExcel: function () {
			const format = "xlsx";
			const exportSelectedOnly = false;
			const filename = this.subjectName;//"test";
			this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
		},

		exportAsCsv: function () {
			const format = "csv";
			const exportSelectedOnly = false;
			const filename = this.subjectName;
			this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
		},
		getAll_ItemsFilterd: function () {
			//https://codepen.io/akmur/pen/YQpgNL
			let tempItems = this.all_items;
			//console.log("this.all_items=",this.all_items);
			let fieldHd = this.fieldHeaders;
			//console.log("this.fieldHeaders=", this.fieldHeaders);
			// console.log("tempItems[this.fieldHeaders[i].value]="+tempItems[0][this.fieldHeaders[1].value])
			// console.log("this.fieldHeaders.length="+this.fieldHeaders.length)
			for (let i = 0; i < fieldHd.length; i++) {
				if (fieldHd[i].model.length > 0) {
					tempItems = tempItems.filter((std) => {
						if (
							isNaN(fieldHd[i].model) 
                            && isNaN(fieldHd[i].value) 
                            && std[fieldHd[i].value] !== null
                            //&& fieldHd[i].value !== null
                            // typeof fieldHd[i].value === "undefined"  
                            //&& fieldHd[i].model.length typeof myVar === "undefined"

						) {
							return std[fieldHd[i].value].toLowerCase().includes(fieldHd[i].model.toLowerCase());
						} else {
							return std[fieldHd[i].value] == fieldHd[i].model;
						}
					});
				}
			}
			return tempItems;
		},

		setSubjectLocStorage(name, data) {
			//console.log("in setSubjectLocStorage, nieuwe this.aSelectedFields = ", this.aSelectedFields	);
			localStorage.setItem(name, JSON.stringify(data));
			//console.log(' localStorage is geset: "' + name + '" = ', JSON.parse(localStorage.getItem(name)));
		},

		getFieldHeaders() {
			// console.log("in getFieldHeaders: this.aFields: ", this.aFields);
			// console.log("in getFieldHeaders: this.aStandardSelFields: ", this.aStandardSelFields);
			

			let aLocalSelFields = this.aSelectedFields;
            
			// let filtered = this.aFields.filter(function (val) {
			// 	return aLocalSelFields.includes(val);
			// });
			this.aSelectedFields = aLocalSelFields;
			this.fieldHeaders = aLocalSelFields.map((val) => {
				if (this.aSelectedFields.includes(val)) {
                    //console.log("this.fieldHeaders: (val = "+val+")", this.fieldHeaders)
					let obj = {};
					obj["text"] = val;
					obj["value"] = val;
					obj["model"] = this.histSearch[val] == null ? "" : this.histSearch[val];
					return obj;
				}
			});            
            //console.log("in getFieldHeaders: this.aSelectedFields: ", this.aSelectedFields);
            //console.log("in getFieldHeaders: this.fieldHeaders: ", this.fieldHeaders);
			//console.log("this.fieldHeaders: ", this.fieldHeaders);
		},

		updateItems() {
			//console.log("In updateItems this.all_items = ",this.all_items);
			this.all_items.map((obj) => {
				obj.checked = this.modelCheckAll;
				return obj;
			});
			this.aFields = Object.keys(this.all_items[0]);

			this.getFieldHeaders();
			console.log("In updateItems() this.fieldHeaders: ", this.fieldHeaders);

			this.all_ItemsFilterd = this.getAll_ItemsFilterd();
			//console.log("this.all_ItemsFilterd", this.all_ItemsFilterd);

            //volgorde velden veranderd niet bij ExcelGrid !! 
            // Hieronder pogingen, niets werkt. Wel kan je op hamburegermenu rechts klikken in grid en kolommen veranderen
            //this.$refs.grid.reset();
            //this.$forceUpdate();
            //console.log("grid zou nu gerest moeten zijn");
            
		},
	},

	watch: {
		//wanneer this.items veranderd van null naar gevuld, runt hij onderstaande
		items: function (newItems) {
			//, oldItems

			if (newItems) {
				//console.log("newItems", newItems); //console.log("oldItems", oldItems);
				this.all_items = newItems;
				this.updateItems();
			}
		},
	},

	computed: {
		all_ItemsFilterd: {
			get: function () {
				// console.log("from computed all_ItemsFilterd. this.aFields=",this.aFields);
				// console.log("from computed all_ItemsFilterd. this.aSelectedFields=",this.aSelectedFields);
				// console.log("from computed all_ItemsFilterd. this.fieldHeaders=",this.fieldHeaders);
				return this.getAll_ItemsFilterd();
			},
			set: function () {
				this.getAll_ItemsFilterd();
				// moet een setter hebben, maar mag leeg zijn..?
			},
		},

		dragOptions() {
			return {
				animation: 500, // tijd in ms
				//group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},
};
</script>

<style scoped>
.tbl_sm {
	font-size: 80%;
	border-collapse: collapse;
}
.tbl_sm td,
.tbl_sm th {
	padding: 0px 4px;
	border: 1px solid gray;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.list-group-item {
	cursor: move;
    background-color:#EEEEFF;
    border:1px solid gray;
    border-radius:5px;
    margin:4px
}

.list-group-item span {
	cursor: pointer;
}
</style> 